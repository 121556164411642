import React from 'react'
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import Logo from '../../images/logo.svg'
import './successPage.css'

const SuccessPage = () => (
  <>
    <Container fluid className="main-success-container">
      <Link to="/"><Logo className="logo" /></Link>
      <h1 className="success-header">You are now <br/> successfully subscribed</h1>
      <p className="success-paragraph">Thank you for joining NagNag's early testing program waitlist. We're <br/> working hard and releasing it soon.<br/>Stay tuned!</p>
    <Container fluid className="success-bottom-container">
    <Container className="terms-botton-inner-container">
        <Row style={{paddingTop: "50px", paddingBottom:"46px"}}>
        <Col>
            <p className="terms-rights-text">NagNag all rights reserved</p>
        </Col>
        <Col>
          <p className="made-by-text">Proudly made by <a href="https://www.lambdaworks.io/" className="lambdaWorks-link"><b>LambdaWorks</b></a></p>
        </Col>
        <Col md="2">
          <Link to="/terms" className="terms-text">Terms of Service</Link> 
        </Col>
        <Col md="2">
          <Link to="/policy" className="policy-text">Privacy Policy</Link> 
        </Col>
        </Row>
      </Container>
    </Container>
    <Container fluid className="mobile-success-bottom-container">
      <Container className="top-margin-terms" />
      <p className="made-by-text">Proudly made by <a href="https://www.lambdaworks.io/" className="lambdaWorks-link"><b>LambdaWorks</b></a></p>
      <Container className="bottom-footer">
        <Container className="links-container">
          <Row style={{flex:1}}>
            <Col style={{textAlign:"right"}}>
              <Link to="/terms" className="mobile-terms-text">Terms of Service</Link> 
            </Col>
            <Col xs={1} style={{marginTop:10, paddingLeft:0, paddingRight:0}}>
              <span style={{fontSize:25}}>&#8739;</span>
            </Col>
            <Col style={{textAlign:"left"}}>
              <Link to="/policy" className="mobile-policy-text">Privacy Policy</Link> 
            </Col>
          </Row>
            <p className="rights-text">NagNag all rights reserved</p>
        </Container>
      </Container>
    </Container>
    </Container>
  </>
)

export default SuccessPage
