import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/seo'
import SuccessPage from '../components/SuccessPage/successPage'

const Success = () => (
  <Layout>
    <SEO title="NagNag"></SEO>
    <SuccessPage />
  </Layout>
)

export default Success
